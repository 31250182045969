import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Autobahn Legends Club
			</title>
			<meta name={"description"} content={"Совершенство на колесах – почувствуйте гордость немецкой техники"} />
			<meta property={"og:title"} content={"Autobahn Legends Club"} />
			<meta property={"og:description"} content={"Совершенство на колесах – почувствуйте гордость немецкой техники"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a0828d4a0c00202fd67d/images/1-2.jpg?v=2024-05-31T12:02:30.799Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71f98e2e8e0021771970"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});